import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { InputField, Btn } from 'components/common';
import { login } from 'services/auth';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { setUser } from 'redux/actions';
import { useNavigate } from 'react-router-dom';

type LoginValues = {
  email: string;
  password: string;
};

const Login = (props) => {
  const { dispatch, user } = props;
  const [submitError, setSubmitError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Email is invalid')
      .required('Email address is required'),
    password: yup.string().required('Password is required'),
  });

  const formMethods = useForm<LoginValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data: LoginValues) => {
    try {
      setSubmitError('');
      setIsLoading(true);

      const { data: submitData, error } = await login(data);
      if (error) {
        setSubmitError(error);
      }
      if (submitData) {
        localStorage.setItem('access_token', submitData?.token);
        dispatch(setUser(submitData?.user));
        navigate('/');
      }
    } catch (e) {
      setSubmitError(String(e));
    }
    setIsLoading(false);
  };

  return (
    <div className="login-container">
      <FormProvider {...formMethods}>
        <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-heading">Login</div>
          <InputField
            inputProps={{
              placeholder: 'Email address',
              ...register('email'),
            }}
            error={errors.email?.message}
          />
          <InputField
            inputProps={{
              placeholder: 'Password',
              type: 'password',
              ...register('password'),
            }}
            error={errors.password?.message}
          />
          {submitError && (
            <div className="submit-error error-text">{submitError}</div>
          )}
          <Btn
            btnClassName="login-button"
            variant="primary"
            type="submit"
            disabled={isLoading}
          >
            Login
          </Btn>
        </Form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(Login);
