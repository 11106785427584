import { Btn, InputField } from 'components/common';
import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { createPiece } from 'services/pieces';
import { toast } from 'react-toastify';
import AutoCompleteField from 'components/common/AutoCompleteField';
import { getMusics, Music } from 'services/music';

type CreatePieceModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  getPieces: VoidFunction;
};

const CreatePieceModal = (props: CreatePieceModalProps) => {
  const { isShowModal, handleCloseModal, getPieces } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isLoadingMusic, setIsLoadingMusic] = useState(false);
  const [musicOptions, setMusicOptions] = useState<
    Array<{ value: Music; label: string; customComponent?: ReactElement }>
  >([]);
  const [selectedMusic, setSelectedMusic] = useState<Music | null>(null);
  const [value, setvalue] = useState<string>('');
  useEffect(() => {
    setError('');
    setIsLoadingMusic(false);
    setMusicOptions([]);
    setSelectedMusic(null);
    setIsLoading(false);
  }, [isShowModal]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!selectedMusic) {
      setError('Please select a piece...');
    }
    const payload = {
      title: selectedMusic?.title || value,
      music: selectedMusic?.url,
    };
    const { data, error } = await createPiece(payload);
    if (data) {
      getPieces();
      handleCloseModal();
      setMusicOptions([]);
      setSelectedMusic(null);
      toast.success('New piece created.');
    }
    if (error) {
      toast.error('Create piece failed. Please try again later');
    }
    setIsLoading(false);
  };

  const handleGetMusic = async (searchText: string) => {
    setIsLoadingMusic(true);
    const { data, error } = await getMusics({ search: searchText, limit: 20 });
    if (data) {
      const options = data?.results.map((i) => ({
        label: i.title,
        value: i,
        customComponent: (
          <div className="music-select-item">
            <div className="music-select-item-title">{i.title}</div>
            <div className="music-select-item-composer">{i.composer}</div>
          </div>
        ),
      }));
      setMusicOptions(options);
    }
    setIsLoadingMusic(false);
  };

  const handleSelect = (item: Music) => {
    setSelectedMusic(item);
    setMusicOptions([]);
  };

  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      className="modal"
    >
      <Modal.Body>
        <div className="modal-content__body add-piece-modal">
          <div className="heading">Add a new piece...</div>
          <div>
            <AutoCompleteField
              inputClassName="mt-4"
              error={error}
              inputProps={{
                placeholder: 'Enter piece title...',
              }}
              isLoading={isLoadingMusic}
              options={musicOptions}
              onCallBack={handleGetMusic}
              onSelect={handleSelect}
              onChange={setvalue}
            />
          </div>
        </div>
        <div className="modal-content__footer">
          <Btn
            variant="outline-secondary"
            onClick={() => handleCloseModal()}
            btnClassName="action__button cancel"
          >
            Cancel
          </Btn>
          <Btn onClick={() => handleSubmit()} variant="primary">
            {isLoading ? (
              <Spinner as="span" animation="border" role="status" />
            ) : (
              'Submit'
            )}
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreatePieceModal;
