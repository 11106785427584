import { Btn, InputSelect, NumberInput } from 'components/common';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Piece } from 'services/pieces';
import { createGoal } from 'services/goals';

type SetGoalModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  piece: Piece;
  handleGetPiece: (v: boolean) => void;
};

const TimeGoal = (props: {
  goalOption?: string;
  title?: string;
  subtitle?: string;
  onClick: VoidFunction;
}) => {
  const { title, subtitle, goalOption, onClick } = props;
  return (
    <div
      className={classNames(
        'time-goal-item',
        goalOption === title ? 'selected' : '',
      )}
      onClick={onClick}
    >
      <div className="time-goal-title">{title}</div>
      <div className="time-goal-subtitle">{subtitle}</div>
    </div>
  );
};
const SetGoalModal = (props: SetGoalModalProps) => {
  const { isShowModal, handleCloseModal, piece, handleGetPiece } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [goalOption, setGoalOption] = useState('1 hr');
  const [hour, setHour] = useState<number | string>(1);
  const [period, setPeriod] = useState('week');

  useEffect(() => {
    setHour(1);
    setPeriod('week');
    setGoalOption('1 hr');
  }, [isShowModal]);

  const handleSubmit = async () => {
    setIsLoading(true);
    let goalDuration = {};
    if (goalOption === '1 hr') {
      goalDuration = {
        duration: 1 * 60 * 60,
        interval: 'week',
      };
    } else if (goalOption === '5 hrs') {
      goalDuration = {
        duration: 5 * 60 * 60,
        interval: 'week',
      };
    } else {
      goalDuration = {
        duration: Number(hour) * 60 * 60,
        interval: period,
      };
    }

    const payload = {
      name: 'Goal',
      piece: piece.url,
      ...goalDuration,
    };

    const { data, error } = await createGoal(payload);
    if (data) {
      handleCloseModal();
      handleGetPiece(false);
      toast.success('Goal created successfully.');
    }

    if (error) {
      toast.error('Error creating goal. Please try again later.');
    }
    setIsLoading(false);
  };

  const goalOptions = [
    {
      value: 'week',
      label: 'Per week',
    },
    {
      value: 'day',
      label: 'Per day',
    },
  ];
  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      className="modal"
    >
      <Modal.Body className="modal-content__body set-goal-modal">
        <div className="header">Set Goal</div>
        <div className="set-goal-form">
          <div className="set-goal-title">Time Goal:</div>
          <div className="time-goal-options">
            <TimeGoal
              title="Custom"
              onClick={() => setGoalOption('Custom')}
              goalOption={goalOption}
            />
            <TimeGoal
              title="1 hr"
              subtitle="per week"
              onClick={() => setGoalOption('1 hr')}
              goalOption={goalOption}
            />
            <TimeGoal
              title="5 hrs"
              subtitle="per week"
              onClick={() => setGoalOption('5 hrs')}
              goalOption={goalOption}
            />
          </div>
          {goalOption === 'Custom' ? (
            <div className="custom-options">
              <NumberInput
                max={168}
                inputProps={{
                  value: hour,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setHour(e.target.value.replace('-', '')),
                  type: 'number',
                }}
                inputClassName="hr-input"
              />
              <div className="hrs-text">hrs</div>
              <InputSelect
                options={goalOptions}
                inputProps={{
                  value: period,
                  onChange: (e: React.ChangeEvent<HTMLSelectElement>) =>
                    setPeriod(e.target.value),
                }}
                inputClassName="option-input"
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="footer-buttons">
          <Btn
            disabled={isLoading}
            onClick={() => handleCloseModal()}
            variant="outline-secondary"
          >
            Cancel
          </Btn>
          <Btn
            btnClassName="save-btn"
            isLoading={isLoading}
            variant="primary"
            onClick={() => handleSubmit()}
          >
            Create
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SetGoalModal;
