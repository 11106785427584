import React, { useState } from 'react';
import SaveSessionForm from './SaveSessionForm';
import StopWatch from './StopWatch';

const Practice = () => {
  const [isShowSaveSessionForm, setIsShowSaveSessionForm] = useState(false);
  const [timeSaved, setTimeSaved] = useState(0);
  const handleSaveForm = (time: number) => {
    setIsShowSaveSessionForm(true);
    setTimeSaved(time);
  };

  const handleDiscard = () => {
    setTimeSaved(0);
    setIsShowSaveSessionForm(false);
  };

  const handleResume = () => {
    setIsShowSaveSessionForm(false);
  };

  return (
    <div className="practice-container">
      {isShowSaveSessionForm ? (
        <SaveSessionForm
          timeSaved={timeSaved}
          handleDiscard={handleDiscard}
          handleResume={handleResume}
        />
      ) : (
        <StopWatch handleSaveForm={handleSaveForm} timeSaved={timeSaved} />
      )}
    </div>
  );
};

export default Practice;
