import {
  Form,
  FormGroupProps,
  FormLabelProps,
  FormControlProps,
} from 'react-bootstrap';
import { default as cs } from 'classnames';

type InputFieldProps = {
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  labelProps?: FormLabelProps;
  inputProps?: Partial<HTMLTextAreaElement> & FormControlProps;
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
};

const InputField = (props: InputFieldProps) => {
  const {
    className,
    inputClassName,
    labelClassName,
    label,
    inputProps,
    labelProps,
    groupProps,
    error,
  } = props;

  return (
    <Form.Group className={cs('input-field-group', className)} {...groupProps}>
      {label && (
        <Form.Label
          className={cs('input-field-label', labelClassName)}
          {...labelProps}
        >
          {label}
        </Form.Label>
      )}
      <Form.Control
        {...inputProps}
        className={cs('input-field-input', inputClassName)}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputField;
