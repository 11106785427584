import * as core from './core';

export type Category = {
  id: number;
  name: string;
  parent: number | null;
};

export type GetCategories = {
  count: number;
  next: number | null;
  previous: number | null;
  results: Array<Category>;
};

export const getCategories = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetCategories;
  error?: string;
}> => {
  return core.get('/categories/', filter) as Promise<{
    data: GetCategories;
    error?: string;
  }>;
};
