import {
  Form,
  FormGroupProps,
  FormLabelProps,
  FormControlProps,
  Spinner,
} from 'react-bootstrap';
import { default as cs } from 'classnames';
import { useState, useCallback, ReactElement } from 'react';
import _debounce from 'lodash/debounce';

type AutoCompleteFieldProps = {
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  labelProps?: FormLabelProps;
  inputProps?: Partial<HTMLTextAreaElement> & FormControlProps;
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
  onChange?: (value: string) => void;
  options?: Array<{
    value: any;
    label: string;
    customComponent?: ReactElement;
  }>;
  onSelect?: (value: any) => void;
  onCallBack?: (value: string) => void;
  isLoading?: boolean;
  isOpenSelect?: boolean;
  isClearValueOnSelect?: boolean;
};

const AutoCompleteField = (props: AutoCompleteFieldProps) => {
  const {
    className,
    inputClassName,
    labelClassName,
    label,
    inputProps,
    labelProps,
    groupProps,
    error,
    options,
    onSelect,
    isLoading,
    onCallBack,
    onChange,
    isOpenSelect = true,
    isClearValueOnSelect = false,
  } = props;
  const [value, setValue] = useState('');

  const handleSelect = (item: { value: any; label: string }) => {
    if (isClearValueOnSelect) {
      setValue('');
    } else {
      setValue(item.label);
    }
    onSelect(item.value);
  };

  const debounceFn = useCallback(_debounce(onCallBack, 500), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange(value);
    setValue(value);
    debounceFn(value);
  };

  return (
    <Form.Group
      className={cs('input-field-group autocomplete', className)}
      {...groupProps}
    >
      {label && (
        <Form.Label
          className={cs('input-field-label autocomplete', labelClassName)}
          {...labelProps}
        >
          {label}
        </Form.Label>
      )}
      <Form.Control
        {...inputProps}
        value={value}
        className={cs('input-field-input autocomplete', inputClassName)}
        onChange={handleChange}
        isInvalid={!!error}
      />
      {isOpenSelect ? (
        <div className="autocomplete-options">
          {isLoading ? (
            <div className="spinner-item">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <>
              {options.map((item, index) => (
                <div
                  key={index}
                  className="autocomplete-item"
                  onClick={() => handleSelect(item)}
                >
                  {item?.customComponent ? item.customComponent : item.label}
                </div>
              ))}
            </>
          )}
        </div>
      ) : null}

      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default AutoCompleteField;
