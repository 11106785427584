import * as core from './core';
import { Goal } from './goals';

export type Piece = {
  id?: number;
  title: string;
  practice_category?: number | null;
  url?: string;
  music?: string;
  goals?: Array<string>;
  sheets?: Array<{
    id: number;
    pdf_url: string;
  }>;
  practiced_time?: number;
  practices?: string[];
};

export type GetPieces = {
  count: number;
  next: number | null;
  previous: number | null;
  results: Array<Piece>;
};

export const getPieces = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetPieces;
  error?: string;
}> => {
  return core.get('/pieces/', filter) as Promise<{
    data: GetPieces;
    error?: string;
  }>;
};

export const createPiece = async (
  payload: Piece,
): Promise<{
  data: Piece;
  error?: string;
}> => {
  return core.post('/pieces/', payload) as Promise<{
    data: Piece;
    error?: string;
  }>;
};

export const getPieceById = async (
  id: string | number,
  filter?: {
    [key: string]: any;
  },
): Promise<{
  data: Piece;
  error?: string;
}> => {
  return core.get(`/pieces/${id}/`, filter) as Promise<{
    data: Piece;
    error?: string;
  }>;
};
