import {
  Form,
  FormGroupProps,
  FormLabelProps,
  FormSelectProps,
} from 'react-bootstrap';
import { default as cs } from 'classnames';
import { ReactElement } from 'react';

type InputSelectProps = {
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  labelProps?: FormLabelProps;
  inputProps?: FormSelectProps;
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
  placeholder?: string;
  options?: Array<{
    value: any;
    label: string;
  }>;
  suffixComponent?: ReactElement;
};

const InputSelect = (props: InputSelectProps) => {
  const {
    className,
    inputClassName,
    labelClassName,
    label,
    inputProps,
    labelProps,
    groupProps,
    error,
    placeholder,
    options,
    suffixComponent,
  } = props;

  return (
    <Form.Group className={cs('input-field-group', className)} {...groupProps}>
      {label && (
        <Form.Label
          className={cs('input-field-label', labelClassName)}
          {...labelProps}
        >
          {label}
        </Form.Label>
      )}
      <div className="input-field-wrapper">
        <Form.Select
          className={cs('input-field-input select', inputClassName)}
          {...inputProps}
          isInvalid={!!error}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options?.map((item, index) => (
            <option value={item.value} key={index}>
              {item.label}
            </option>
          ))}
        </Form.Select>
        {suffixComponent}
      </div>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputSelect;
