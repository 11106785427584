import React, { ReactElement, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import YouTube, { YouTubeProps } from 'react-youtube';

type YoutubeModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  id: string;
};

const YoutubeModal = (props: YoutubeModalProps) => {
  const { isShowModal, handleCloseModal, id } = props;

  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.pauseVideo();
  };

  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      size="lg"
      className="modal youtube-modal-wrapper"
    >
      <Modal.Body className="youtube-modal">
        <div className="videoWrapper">
          <YouTube videoId={id} opts={opts} onReady={onPlayerReady} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default YoutubeModal;
