import config from 'config';
import axios from 'axios';
import qs from 'qs';

const apiEnpoint = `${config.API_ENDPOINT}`;

const getHeaders = (token?: string) => {
  let localToken = localStorage.getItem('access_token') || token;
  if (localToken) {
    return {
      Accept: '*/*',
      'Content-type': 'application/json',
      Authorization: 'JWT ' + localToken,
    };
  } else {
    return {
      Accept: '*/*',
      'Content-type': 'application/json',
    };
  }
};

const getErrorMessage = (error: any) => {
  let { status, data } = error?.response || {};
  let msg = '';
  if (status === 500) {
    msg = 'Server error. Please try again later';
  } else {
    msg = data?.non_field_errors?.join(' ');
  }
  if (!msg) msg = 'Failed to make request. Please try again later';
  return msg;
};

const get = async (
  path: string,
  filter?: { [key: string]: any },
  customUrl?: string,
) => {
  try {
    let query;
    if (filter) {
      query = qs.stringify(filter);
    }
    if (customUrl) {
      let { data } = await axios.get(customUrl, {
        headers: getHeaders(),
      });
      return { data };
    }
    let { data } = await axios.get(
      `${apiEnpoint}${path}${query ? `?${query}` : ''}`,
      {
        headers: getHeaders(),
      },
    );
    return { data };
  } catch (error) {
    return { error: getErrorMessage(error) };
  }
};

const post = async (path: string, payload?: any) => {
  try {
    let { data } = await axios.post(`${apiEnpoint}${path}`, payload, {
      headers: getHeaders(),
    });
    return { data };
  } catch (error) {
    return { error: getErrorMessage(error) };
  }
};

const put = async (path: string, payload?: any) => {
  try {
    let { data } = await axios.put(`${apiEnpoint}${path}`, payload, {
      headers: getHeaders(),
    });
    return { data };
  } catch (error) {
    return { error: getErrorMessage(error) };
  }
};

const patch = async (path: string, payload?: any) => {
  try {
    let { data } = await axios.patch(`${apiEnpoint}${path}`, payload, {
      headers: getHeaders(),
    });
    return { data };
  } catch (error) {
    return { error: getErrorMessage(error) };
  }
};

export { patch, get, post, put, getErrorMessage, getHeaders };
