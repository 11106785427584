import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getPieces, Piece } from 'services/pieces';

const MusicLibrary = () => {
  const [pieces, setPieces] = useState<Array<Piece>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetPieces();
  }, []);

  const handleGetPieces = async () => {
    setIsLoading(true);
    const { data } = await getPieces({ limit: 100, ordering: '-created' });
    if (data) {
      setPieces(data?.results);
    }
    setIsLoading(false);
  };

  return (
    <div className="music-library-container">
      <div className="piece-wrapper">
        {isLoading ? (
          <div>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <>
            {pieces?.map((item, index) => (
              <Link to={`/music-library/${item.id}`} key={index}>
                <div className="piece-item">
                  <div className="piece-item-title">{item?.title}</div>
                </div>
              </Link>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MusicLibrary;
