import { Btn, InputField, InputSelect, NumberInput } from 'components/common';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Practice, updatePracticeSession } from 'services/practice';
import { FormProvider, useForm } from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPieces, Piece } from 'services/pieces';
import moment from 'moment';
import { Category } from 'services/categories';
import { toast } from 'react-toastify';

type PracticeEditModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  practice?: Practice;
  categories: Category[];
  handleGetPractices: (v: boolean) => void;
  triggerGoalReload: VoidFunction;
};

type SaveSessionFormValues = {
  duration?: string;
  practice_category?: string;
  notes?: string;
  piece?: string;
  created?: Date | string;
};

const PracticeEditModal = (props: PracticeEditModalProps) => {
  const {
    isShowModal,
    handleCloseModal,
    categories,
    practice,
    triggerGoalReload,
    handleGetPractices,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pieces, setPieces] = useState<Array<Piece>>([]);

  useEffect(() => {
    handleGetPieces();
  }, []);

  const schema = yup.object().shape({
    duration: yup
      .number()
      .typeError('Please enter a valid number')
      .integer('Please enter an integer')
      .max(168)
      .min(1, 'Duration must be greater than 0'),
    piece: yup.string().nullable().notRequired(),
    practice_category: yup.string().nullable().notRequired(),
    notes: yup.string().nullable().notRequired(),
    created: yup
      .date()
      .max(new Date(), 'Practice date must not be future date'),
  });

  const formMethods = useForm<SaveSessionFormValues>({
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;
  useEffect(() => {
    if (practice) {
      setValue('notes', practice?.notes);
      setValue('duration', String(Math.floor(Number(practice?.duration) / 60)));
      setValue('piece', practice?.piece?.url);
      setValue('practice_category', String(practice?.practice_category));
      setValue('created', moment(practice.created).format('YYYY-MM-DD'));
    }
  }, [practice]);
  useEffect(() => {
    setIsLoading(false);
  }, [isShowModal]);

  const handleGetPieces = async () => {
    const { data } = await getPieces({ limit: 20, ordering: '-created' });
    if (data) {
      setPieces(data?.results);
    }
  };

  const onSubmit = async (values: SaveSessionFormValues) => {
    setIsLoading(true);
    const payload = {
      duration: (Number(values?.duration) || 0) * 60,
      created: new Date(values?.created).toISOString(),
      notes: values?.notes || '',
      practice_category: Number(values?.practice_category) || 1,
      piece: values?.piece || null,
    };
    const { data, error } = await updatePracticeSession(practice.id, payload);

    if (data) {
      toast.success(`Practice session updated!`);
      handleCloseModal();
      handleGetPractices(false);
      triggerGoalReload();
    }
    if (error) {
      toast.error('Save session failed. Please try again later.');
    }
    setIsLoading(false);
  };

  const categoryOptions = useMemo(() => {
    return categories.map((i) => ({ value: String(i.id), label: i.name }));
  }, [categories]);

  const pieceOptions = useMemo(() => {
    return pieces.map((i) => ({ value: i.url, label: i.title }));
  }, [pieces]);

  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      className="modal"
    >
      <Modal.Body>
        <FormProvider {...formMethods}>
          <Form className="save-session-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content__body practice-edit-modal">
              <div className="heading">Edit session</div>

              <InputSelect
                label="I'm practicing..."
                options={pieceOptions}
                placeholder="Select piece..."
                inputProps={{
                  ...register('piece'),
                }}
              />
              <div className="practice-time-date">
                <NumberInput
                  max={168}
                  min={0}
                  isInt={true}
                  label="Practice time (minutes)"
                  inputProps={{
                    value: watch('duration'),
                    type: 'number',
                    ...register('duration'),
                  }}
                  error={errors.duration?.message}
                  className="hr-input"
                />
                <InputField
                  label="Practice date"
                  inputProps={{
                    type: 'date',
                    ...register('created', { valueAsDate: false }),
                  }}
                  error={errors.created?.message}
                  className={'date-input'}
                />
              </div>
              <InputField
                label="Notes"
                inputProps={{
                  placeholder: 'Optional',
                  as: 'textarea',
                  ...register('notes'),
                }}
                inputClassName={'notes-input'}
              />
              <InputSelect
                label="Category"
                options={categoryOptions}
                placeholder="Select category..."
                inputProps={{
                  ...register('practice_category'),
                }}
              />
            </div>
            <div className="modal-content__footer">
              <Btn
                variant="outline-secondary"
                onClick={() => handleCloseModal()}
                btnClassName="action__button cancel"
              >
                Cancel
              </Btn>
              <Btn type="submit" variant="primary" disabled={isLoading}>
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                  />
                ) : (
                  'Submit'
                )}
              </Btn>
            </div>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default PracticeEditModal;
