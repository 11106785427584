import React, { useEffect, useState } from 'react';
import { getPracticesTimechart, getPracticesStats } from 'services/practice';
import { formatDuration, generateDateLabels } from 'ultils';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';
import { InputSelect } from 'components/common';
import RecentPracticed from './RecentPracticed';
import Goals from './Goals';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

const Home = () => {
  const [overviewData, setOverviewData] = useState<{
    duration: string;
    sessions: number;
    dateRange: string;
  }>({ duration: '0', sessions: 0, dateRange: '-' });
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [range, setRange] = useState<string>('week');
  const [chartData, setChartData] = useState<{
    labels: string[];
    data: number[];
    totalTime: number;
  }>({ labels: [], data: [], totalTime: 0 });
  const [goalReload, setGoalReload] = useState(0);

  useEffect(() => {
    handleGetPracticesTimeChart();
  }, [range]);

  const handleGetPracticesTimeChart = async () => {
    setIsLoadingChart(true);
    let created_min = '';
    let period = '';
    switch (range) {
      case 'week':
        created_min = moment().startOf('week').toISOString();
        period = '1w';
        break;
      case 'month':
        created_min = moment().startOf('month').toISOString();
        period = '1m';
        break;
      case 'year':
        created_min = moment().startOf('year').toISOString();
        period = '1y';
        break;
      default:
        break;
    }
    const { data: statData, error: statError } = await getPracticesStats({
      period,
    });
    if (statData) {
      const extractedData = Object.values(statData)[0];
      const overviewData = {
        duration: formatDuration(extractedData.duration),
        sessions: extractedData.sessions,
        dateRange: `${moment(created_min).format('MMM DD')} - ${moment().format(
          'MMM DD',
        )}`,
      };
      setOverviewData(overviewData);
    }
    const { data, error } = await getPracticesTimechart({ created_min });
    if (data) {
      const extractedData = Object.values(data?.datasets)[0];
      const labels = generateDateLabels(
        extractedData.start_date,
        extractedData.end_date,
      );
      let yearData: number[] = [];
      const monthLabels = generateDateLabels(
        extractedData.start_date,
        extractedData.end_date,
        range,
      );

      if (range === 'year') {
        const monthObject = {};
        for (let i = 0; i < labels.length; i++) {
          const currentMonth = labels[i].substring(0, 3);
          if (monthObject[currentMonth]) {
            monthObject[currentMonth] += extractedData.values[i] || 0;
          } else {
            monthObject[currentMonth] = extractedData.values[i] || 0;
          }
        }
        yearData = Object.values(monthObject);
      }
      setChartData({
        labels: range === 'year' ? monthLabels : labels,
        data:
          range === 'year'
            ? yearData.map((i) => i / 60)
            : extractedData.values.map((i) => i / 60),
        totalTime: extractedData.total_time,
      });
    }
    setIsLoadingChart(false);
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        display: true,
        beginAtZero: true,
        ticks: { stepSize: 1 },
      },
    },
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        label: 'Minutes practiced',
        borderColor: '#2898d1',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.5,
      },
    ],
  };

  const selectOptions = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
  ];

  return (
    <div className="home-container">
      <div className="chart-container">
        <div className="chart-controllers">
          <InputSelect
            inputClassName="chart-controllers-input"
            options={selectOptions}
            inputProps={{
              onChange: (e) => setRange(e.target.value),
            }}
          />
          <div className="chart-controllers-overview">
            <div className="overview-item">
              <div className="overview-item-title">Time</div>
              <div className="overview-item-value">{overviewData.duration}</div>
            </div>
            <div className="overview-item">
              <div className="overview-item-title">Sessions</div>
              <div className="overview-item-value">{overviewData.sessions}</div>
            </div>
            <div className="overview-item">
              <div className="overview-item-title">Date Range</div>
              <div className="overview-item-value">
                {overviewData.dateRange}
              </div>
            </div>
          </div>
        </div>
        <div className="chart-wrapper">
          <Line options={options} data={data} />
        </div>
      </div>
      <Goals goalReload={goalReload} />
      <RecentPracticed
        triggerGoalReload={() => setGoalReload((prev) => prev + 1)}
      />
    </div>
  );
};

export default Home;
