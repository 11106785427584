import ReactPaginate from 'react-paginate';

type PaginationProps = {
  isShowFooter?: boolean;
  numPages: number;
  handlePageChange: (page: number) => void;
  pageNumber: number;
};

const Pagination = (props: PaginationProps) => {
  const { isShowFooter, numPages, handlePageChange, pageNumber } = props;

  return (
    <div className="pagination-container">
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        pageCount={numPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={(event) => handlePageChange(event.selected)}
        containerClassName={'pagination'}
        activeClassName={'page-item active'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
      />
      {isShowFooter ? (
        <div className="pdf-wrapper__page-number">
          Page {pageNumber} of {numPages}
        </div>
      ) : null}
    </div>
  );
};

export default Pagination;
