import * as core from './core';

export type Question = {
  id: number;
  url: string;
  music: string;
  title: string;
  description: string;
  rating: number;
  username: string;
  created_date: string;
  total_answers: number;
};

export type Music = {
  id: number;
  url: string;
  title: string;
  rating: string;
  composer: string;
  sheetmusic_previews: string[];
  tags: string[];
  questions: Question[];
  youtube_video_ids: string[];
  sheets?: Array<{ id: number; pdf_url: string }>;
};

export type Answer = {
  id: number;
  url: string;
  username: string;
  description: string;
  created_date: string;
  rating: number;
  question: string;
};

export type GetAnswers = {
  count: number;
  results: Answer[];
};

export type GetMusic = {
  count: number;
  results: Music[];
};

export const getMusics = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetMusic;
  error?: string;
}> => {
  return core.get('/music/', filter) as Promise<{
    data: GetMusic;
    error?: string;
  }>;
};

export const getMusicByUrl = async (
  url: string,
): Promise<{
  data: Music;
  error?: string;
}> => {
  return core.get('', undefined, url) as Promise<{
    data: Music;
    error?: string;
  }>;
};

export const getAnswers = async (filter?: {
  question: number;
  [key: string]: any;
}): Promise<{
  data: GetAnswers;
  error?: string;
}> => {
  return core.get('/answers/', filter) as Promise<{
    data: GetAnswers;
    error?: string;
  }>;
};

export const createAnswer = async (
  payload: any,
): Promise<{
  data: Answer;
  error?: string;
}> => {
  return core.post('/answers/', payload) as Promise<{
    data: Answer;
    error?: string;
  }>;
};

export const createQuestion = async (
  payload: any,
): Promise<{
  data: Answer;
  error?: string;
}> => {
  return core.post('/questions/', payload) as Promise<{
    data: Answer;
    error?: string;
  }>;
};
