import { Btn, InputField } from 'components/common';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createQuestion, Music } from 'services/music';

type CreateDiscussionModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  getQuestions: (isSetLoading: false) => void;
  music?: Music;
};

const CreateDiscussionModal = (props: CreateDiscussionModalProps) => {
  const { isShowModal, handleCloseModal, getQuestions, music } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setTitle('');
    setDescription('');
    setIsLoading(false);
  }, [isShowModal]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!title) {
      return;
    }
    const payload = {
      music: music?.url,
      title: title,
      description: description,
    };
    const { data, error } = await createQuestion(payload);
    if (data) {
      getQuestions(false);
      handleCloseModal();
      toast.success('New discussion created.');
    }
    if (error) {
      toast.error('Create dicussion failed. Please try again later');
    }
    setIsLoading(false);
  };

  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      className="modal"
    >
      <Modal.Body>
        <div className="modal-content__body add-piece-modal">
          <div className="heading">Start a dicussion</div>
          <InputField
            label="Title"
            inputProps={{
              value: title,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value),
              placeholder: 'Enter title...',
            }}
          />
          <InputField
            label="Description"
            inputClassName="text-area-input"
            inputProps={{
              value: description,
              as: 'textarea',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setDescription(e.target.value),
              placeholder: 'Enter description...',
            }}
          />
        </div>
        <div className="modal-content__footer">
          <Btn
            variant="outline-secondary"
            onClick={() => handleCloseModal()}
            btnClassName="action__button cancel"
          >
            Cancel
          </Btn>
          <Btn onClick={() => handleSubmit()} variant="primary">
            {isLoading ? (
              <Spinner as="span" animation="border" role="status" size="sm" />
            ) : (
              'Submit'
            )}
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateDiscussionModal;
