import * as core from './core';

export type User = {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  picture?: string;
  timezone?: string;
  practice_categories?: number[];
  instruments?: number[];
};

export const getUser = async (): Promise<{ data: User; error?: string }> => {
  return core.get('/auth/user/') as Promise<{ data: User; error?: string }>;
};

export const updateUser = async (
  payload: User,
): Promise<{
  data: { token: string; user: User };
  error?: string;
}> => {
  return core.put('/auth/user/', payload) as Promise<{
    data: { token: string; user: User };
    error?: string;
  }>;
};

export const getTimezones = async (): Promise<{
  data: string[];
  error?: string;
}> => {
  return core.get('/timezones/') as Promise<{ data: string[]; error?: string }>;
};
