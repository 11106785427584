import * as core from './core';
import { Piece } from './pieces';

export type Goal = {
  id?: number;
  url?: string;
  name?: string;
  duration?: number;
  interval?: string;
  progress?: string;
  is_arvhived?: boolean;
  piece?: Piece;
};

export const createGoal = async (
  payload: Omit<Goal, 'piece'> & { piece: string },
): Promise<{
  data: Goal;
  error?: string;
}> => {
  return core.post('/goals/', payload) as Promise<{
    data: Goal;
    error?: string;
  }>;
};

export type GetGoals = {
  count: number;
  next: number | null;
  previous: number | null;
  results: Array<Goal>;
};

export const getGoals = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetGoals;
  error?: string;
}> => {
  return core.get('/goals/', filter) as Promise<{
    data: GetGoals;
    error?: string;
  }>;
};

export const getGoalByUrl = async (
  url: string,
): Promise<{
  data: Goal;
  error?: string;
}> => {
  return core.get('', undefined, url) as Promise<{
    data: Goal;
    error?: string;
  }>;
};
