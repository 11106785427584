import { ReactNode } from 'react';
import Btn from '../Btn';
import { Modal } from 'react-bootstrap';

type Props = {
  children?: ReactNode;
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  onSubmit: VoidFunction;
  isLoading?: boolean;
  title?: string;
};

function ConfirmationModal({
  title = 'Are you sure?',
  children,
  handleCloseModal,
  onSubmit,
  isLoading,
  isShowModal,
}: Props) {
  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      className="modal"
    >
      <Modal.Body className="confirmation-modal">
        <div className="header">{title}</div>
        <div>{children}</div>
        <div className="footer-buttons">
          <Btn
            disabled={isLoading}
            onClick={() => handleCloseModal()}
            variant="outline-secondary"
          >
            Cancel
          </Btn>
          <Btn
            btnClassName="save-btn"
            isLoading={isLoading}
            variant="primary"
            onClick={() => onSubmit()}
          >
            Yes
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationModal;
