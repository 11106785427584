import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import {
  Home,
  Login,
  Practice,
  MusicLibrary,
  MusicLibraryItem,
  Settings,
} from './pages';
import { AppLayout, AuthRoute, PrivateRoute, MainLayout } from './layouts';

const App = () => {
  return (
    <Router>
      <AppLayout>
        <Switch>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout>
                  <Home />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/practice"
            element={
              <PrivateRoute>
                <MainLayout>
                  <Practice />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/music-library"
            element={
              <PrivateRoute>
                <MainLayout>
                  <MusicLibrary />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <MainLayout>
                  <Settings />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/music-library/:id"
            element={
              <PrivateRoute>
                <MainLayout>
                  <MusicLibraryItem />
                </MainLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRoute>
                <Login />
              </AuthRoute>
            }
          />
        </Switch>
      </AppLayout>
    </Router>
  );
};

export default App;
