import React, { useEffect, useRef, useState } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { default as cs } from 'classnames';
import { secondsToMinutesFormat } from 'ultils';

type StopWatchProps = {
  handleSaveForm: (time: number) => void;
  timeSaved: number;
};

const StopWatch = (props: StopWatchProps) => {
  const { handleSaveForm, timeSaved } = props;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [time, setTime] = useState<number>(0);

  const counterIntervalRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      const counterInterval = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
      counterIntervalRef.current = counterInterval;
    } else {
      clearInterval(counterIntervalRef.current);
    }
  }, [isActive]);

  useEffect(() => {
    if (timeSaved !== 0) {
      setTime(timeSaved);
      setIsActive(true);
    }
  }, [timeSaved]);

  const handleStartClock = () => {
    setIsActive(true);
    setTime(0);
  };

  const handleResume = () => {
    setIsActive(false);
  };

  const handleStopClock = () => {
    handleSaveForm(time);
    setIsActive(false);
  };

  return (
    <div className="stop-watch-wrapper">
      <div className={cs('practicing-text', !isActive ? 'opacity-0' : '')}>
        Practicing
      </div>
      <div className="clock">{secondsToMinutesFormat(time)}</div>
      <div className="button-wrapper">
        {!isActive && (
          <div
            className="control-button start"
            onClick={() => handleStartClock()}
          >
            <FaPlay size={22} />
          </div>
        )}
        {isActive && (
          <div
            className="control-button pause"
            onClick={() => handleStopClock()}
          >
            <FaPause size={22} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StopWatch;
