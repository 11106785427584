import { Btn, InputField } from 'components/common';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Answer, createAnswer, getAnswers, Question } from 'services/music';
import { MdSend } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
type DiscussionModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  question: Question;
};

const DiscussionModal = (props: DiscussionModalProps) => {
  const { isShowModal, handleCloseModal, question } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  useEffect(() => {
    if (isShowModal) {
      handleGetAnswers();
    } else {
      setAnswers([]);
      setText('');
    }
  }, [isShowModal]);

  const handleGetAnswers = async (isSetLoading = true) => {
    if (isSetLoading) setIsLoading(true);
    const { data, error } = await getAnswers({
      question: question.id,
      ordering: '-created',
    });
    if (data) {
      setAnswers(data.results);
    }
    if (error) {
      toast.error('Error getting answers for this question.');
    }
    if (isSetLoading) setIsLoading(false);
  };

  const handleCreateAnswer = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!text) {
      return;
    }
    setIsLoadingSubmit(true);
    const payload = {
      question: question.url,
      description: text,
    };
    const { data, error } = await createAnswer(payload);
    if (data) {
      handleGetAnswers(false);
      setText('');
    }
    if (error) {
      toast.error('Error submitting asnwer. Please try again later');
    }
    setIsLoadingSubmit(false);
  };

  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      size="lg"
      className="modal"
    >
      <Modal.Body className="modal-content__body discussion-modal">
        <div className="main-title">{question?.title}</div>
        <div className="question-date">
          {moment(question?.created_date).fromNow()}
        </div>
        <div className="question-description">{question?.description}</div>
        <div className="asnwers-title">ANSWERS</div>
        <div className="question-answers-wrapper">
          {isLoading ? (
            <div>
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <>
              {answers.length > 0 ? (
                <>
                  {answers.map((item, index) => (
                    <div className="answer-item">
                      <div className="item-description">{item.description}</div>
                      <div className="item-date">
                        {item.username ? `${item.username} •` : ''}{' '}
                        {moment(item.created_date).fromNow()}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="no-answer">There is no answer.</div>
              )}
            </>
          )}
        </div>
        <Form className="text-area" onSubmit={handleCreateAnswer}>
          <InputField
            inputProps={{
              placeholder: 'Type your answer...',
              value: text,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setText(e.target.value),
            }}
          />
          <Btn
            variant="primary"
            className="send-button"
            disabled={isLoadingSubmit}
            type="submit"
          >
            <MdSend size={22} />
          </Btn>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DiscussionModal;
