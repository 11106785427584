import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, User } from 'services/user';
import { setUser } from 'redux/actions';
import { Spinner } from 'react-bootstrap';

type AuthRouteProps = {
  user?: User;
  dispatch: (payload: { type: string; user: User }) => void;
  children: ReactElement;
};

const AuthRoute = (props: AuthRouteProps) => {
  const { children, user, dispatch } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      let token = localStorage.getItem('access_token');
      if (token) {
        getCurrentUser();
      } else {
        localStorage.removeItem('access_token');
      }
    }
  }, [user]);

  const getCurrentUser = async () => {
    try {
      setIsLoading(true);
      let { data, error } = await getUser();
      if (error) throw error;
      if (data) {
        dispatch(setUser(data));
        navigate('/');
      }
    } catch (e) {
      dispatch(setUser(null));
      localStorage.removeItem('access_token');
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return children;
  } else {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(AuthRoute);
