import React, { useState } from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EditProfile from './EditProfile';

const Settings = () => {
  return (
    <div className="settings-container">
      <Tabs defaultActiveKey="profile" className="tabs-container">
        <Tab eventKey="profile" title="Edit profile">
          <EditProfile />
        </Tab>
        <Tab eventKey="classes" title="Classes joined"></Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Settings);
