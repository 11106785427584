import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const AppLayout = (props) => {
  const { children } = props;
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="app-layout">
      <ToastContainer hideProgressBar={true} theme="colored" />
      {children}
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AppLayout);
