import { Btn, InputField } from 'components/common';
import React, { useRef, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { throttle } from 'lodash';
import { Music } from 'services/music';
import Pagination from 'components/common/Pagination';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type PdfModalProps = {
  isShowModal: boolean;
  handleCloseModal: VoidFunction;
  music?: Music;
};

const PdfModal = (props: PdfModalProps) => {
  const { isShowModal, handleCloseModal, music } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentWidth, setDocumentWidth] = useState(0);
  const documentWrapperRef = useRef(null);

  const setDocWidth = () => {
    setDocumentWidth(documentWrapperRef?.current?.offsetWidth);
  };
  useEffect(() => {
    if (documentWrapperRef?.current) {
      setDocumentWidth(documentWrapperRef?.current?.offsetWidth);
      window.addEventListener('resize', throttle(setDocWidth, 500));
    }
    return () => {
      window.removeEventListener('resize', throttle(setDocWidth, 500));
    };
  }, [isShowModal, music]);

  useEffect(() => {}, [isShowModal]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const handlePageChange = (page: number) => {
    setPageNumber(page + 1);
  };

  return (
    <Modal
      show={isShowModal}
      onHide={handleCloseModal}
      centered
      size="lg"
      className="modal"
    >
      <Modal.Body className="modal-content__body pdf-modal">
        {music?.sheets?.[0]?.pdf_url ? (
          <div className="pdf-wrapper" ref={documentWrapperRef}>
            <Document
              file={music?.sheets?.[0]?.pdf_url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(e) => console.log(e)}
            >
              <Page
                pageNumber={pageNumber}
                renderMode="svg"
                width={documentWidth || undefined}
              />
              <Pagination
                numPages={numPages}
                isShowFooter={true}
                pageNumber={pageNumber}
                handlePageChange={handlePageChange}
              />
            </Document>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default PdfModal;
