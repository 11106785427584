import React, { useEffect, useMemo, useState } from 'react';
import { Category, getCategories } from 'services/categories';
import { getPractices, Practice } from 'services/practice';
import { Spinner } from 'react-bootstrap';
import { formatDuration } from 'ultils';
import moment from 'moment';
import { toast } from 'react-toastify';
import Pagination from 'components/common/Pagination';
import PracticeEditModal from './PracticeEditModal';
import { User } from 'services/user';
import { connect } from 'react-redux';

type RecentPracticedProps = {
  triggerGoalReload: VoidFunction;
  user: User;
};

const RecentPracticed = (props: RecentPracticedProps) => {
  const { triggerGoalReload, user } = props;
  const [practices, setPractices] = useState<Array<Practice>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPractice, setSelectedPractice] = useState<Practice | null>(
    null,
  );
  const [categories, setCategories] = useState<Array<Category>>([]);

  useEffect(() => {
    handleGetPractices();
  }, [pageNumber]);

  const handleGetPractices = async (isShowLoading: boolean = true) => {
    if (isShowLoading) setIsLoading(true);
    const { data: categoryData } = await getCategories({ limit: 99 });

    const { data, error } = await getPractices({
      limit: 10,
      offset: (pageNumber - 1) * 10,
      ordering: '-created',
      embed: 'piece',
    });

    if (data) {
      setCategories(categoryData.results);
      const practices = data?.results.map((i) => {
        const practice_category =
          categoryData?.results?.find((item) => item.id === i.practice_category)
            ?.name || 'Practice Session';
        return {
          ...i,
          practice_category_text: practice_category,
        };
      });
      setNumPages(data?.count / 10);
      setPractices(practices);
    }
    if (error) {
      toast.error('Error getting practices. Please try again later');
    }
    setIsLoading(false);
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page + 1);
  };

  const handleOpenEditModal = (item: Practice) => {
    setSelectedPractice(item);
  };

  const categoriesSelectOptions = useMemo(() => {
    return categories.filter((i) => user?.practice_categories.includes(i.id));
  }, [categories]);
  return (
    <>
      <PracticeEditModal
        isShowModal={!!selectedPractice}
        handleCloseModal={() => setSelectedPractice(null)}
        practice={selectedPractice}
        categories={categoriesSelectOptions}
        handleGetPractices={handleGetPractices}
        triggerGoalReload={triggerGoalReload}
      />
      <div className="heading">Recent practiced</div>
      <div className="practices-wrapper">
        {isLoading ? (
          <div>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <>
            {practices?.map((item, index) => (
              <div
                key={index}
                className="practice-item home-item"
                onClick={() => handleOpenEditModal(item)}
              >
                <div className="practice-item-date">
                  {moment(item?.created).format('MM-DD-YYYY')}
                </div>
                <div className="practice-item-name">
                  {item?.practice_category_text}
                </div>
                <div className="practice-item-duration">
                  {formatDuration(item?.duration)}
                </div>
                <div className="practice-item-note">{item?.piece?.title}</div>
              </div>
            ))}
          </>
        )}
        <Pagination
          numPages={numPages}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(RecentPracticed);
