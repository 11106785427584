import {
  Btn,
  InputField,
  InputSelect,
  ConfirmationModal,
} from 'components/common';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Category, getCategories } from 'services/categories';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { formatDuration, secondsToMinutesFormat } from 'ultils';
import { savePracticeSession, Practice } from 'services/practice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Piece, getPieces } from 'services/pieces';
import { FaPlus } from 'react-icons/fa';
import CreatePieceModal from './CreatePieceModal';
import { BsArrowLeft } from 'react-icons/bs';
import { User } from 'services/user';
import { connect } from 'react-redux';

type SaveSessionFormProps = {
  timeSaved: number;
  handleDiscard: VoidFunction;
  handleResume: VoidFunction;
  user: User;
};

type SaveSessionFormValues = {
  practice_category?: string;
  notes: string;
  piece: string;
};

const SaveSessionForm = (props: SaveSessionFormProps) => {
  const { timeSaved, handleDiscard, handleResume, user } = props;
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [pieces, setPieces] = useState<Array<Piece>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAddPiece, setIsShowAddPiece] = useState(false);
  const [isShowDiscardConfirm, setIsShowDiscardConfirm] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      handleGetCategories();
      handleGetPieces();
    }
  }, [user]);

  const schema = yup.object().shape({
    piece: yup.string().nullable().notRequired(),
    practice_category: yup.string().nullable().notRequired(),
    notes: yup.string().nullable().notRequired(),
  });

  const formMethods = useForm<SaveSessionFormValues>({
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleGetCategories = async () => {
    const { data } = await getCategories({ limit: 99 });
    if (data) {
      setCategories(
        data?.results.filter((i) => user.practice_categories?.includes(i.id)),
      );
    }
  };

  const handleGetPieces = async () => {
    const { data } = await getPieces({ limit: 20, ordering: '-created' });
    if (data) {
      setPieces(data?.results);
    }
  };

  const onSubmit = async (values: SaveSessionFormValues) => {
    setIsLoading(true);
    const payload = {
      duration: timeSaved,
      created: new Date().toISOString(),
      notes: values?.notes || '',
      practice_category: Number(values?.practice_category) || 1,
      piece: values?.piece || null,
    };
    const { data, error } = await savePracticeSession(payload);

    if (data) {
      toast.success(`Nice work! You practiced ${formatDuration(timeSaved)}`);
      navigate('/');
    }
    if (error) {
      toast.error('Save session failed. Please try again later.');
    }
    setIsLoading(false);
  };

  const categoryOptions = useMemo(() => {
    return categories.map((i) => ({ value: i.id, label: i.name }));
  }, [categories]);

  const pieceOptions = useMemo(() => {
    return pieces.map((i) => ({ value: i.url, label: i.title }));
  }, [pieces]);

  return (
    <div className="save-session-form-container">
      <ConfirmationModal
        isShowModal={isShowDiscardConfirm}
        handleCloseModal={() => setIsShowDiscardConfirm(false)}
        onSubmit={() => handleDiscard()}
      >
        <div>Are you sure you you want to discard this practice session?</div>
      </ConfirmationModal>
      <CreatePieceModal
        isShowModal={isShowAddPiece}
        getPieces={handleGetPieces}
        handleCloseModal={() => setIsShowAddPiece(false)}
      />
      <FormProvider {...formMethods}>
        <div className="go-back-button" onClick={() => handleResume()}>
          <BsArrowLeft /> Resume timer
        </div>
        <Form className="save-session-form" onSubmit={handleSubmit(onSubmit)}>
          <InputSelect
            label="I'm practicing..."
            options={pieceOptions}
            placeholder="Select piece..."
            inputProps={{
              ...register('piece'),
            }}
            suffixComponent={
              <Btn
                variant="white"
                btnClassName="button-add"
                onClick={() => setIsShowAddPiece(true)}
              >
                <FaPlus size={20} />
              </Btn>
            }
          />
          <Form.Group className="input-field-group">
            <Form.Label className="input-field-label">
              Practice time (minutes)
            </Form.Label>
            <div className="form-minute">
              {secondsToMinutesFormat(timeSaved)}
            </div>
          </Form.Group>

          <InputField
            label="Notes"
            inputProps={{
              placeholder: 'Optional',
              as: 'textarea',
              ...register('notes'),
            }}
            inputClassName={'notes-input'}
          />
          <InputSelect
            label="Category"
            options={categoryOptions}
            placeholder="Select category..."
            inputProps={{
              ...register('practice_category'),
            }}
          />
          <div className="button-container">
            <Btn
              variant="outline-secondary"
              onClick={() => setIsShowDiscardConfirm(true)}
            >
              Discard
            </Btn>
            <Btn variant="primary" type="submit" disabled={isLoading}>
              Save
            </Btn>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(SaveSessionForm);
