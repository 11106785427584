import {
  Form,
  FormGroupProps,
  FormLabelProps,
  FormControlProps,
} from 'react-bootstrap';
import { default as cs } from 'classnames';
import { ChangeEvent } from 'react';

type NumberInputProps = {
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  labelProps?: FormLabelProps;
  inputProps?: FormControlProps;
  groupProps?: FormGroupProps;
  className?: string;
  error?: string;
  max?: number;
  min?: number;
  isInt?: boolean;
};

const NumberInput = (props: NumberInputProps) => {
  const {
    className,
    inputClassName,
    labelClassName,
    label,
    inputProps,
    labelProps,
    groupProps,
    error,
    max,
    min,
    isInt,
  } = props;

  if (Number(inputProps?.value) > max) {
    if (inputProps?.onChange)
      inputProps.onChange({
        target: { name: '', value: String(max) },
      } as ChangeEvent<HTMLInputElement>);
  }

  if (Number(inputProps?.value) < min) {
    if (inputProps?.onChange)
      inputProps.onChange({
        target: { name: '', value: String(min) },
      } as ChangeEvent<HTMLInputElement>);
  }

  if (isInt) {
    if (inputProps?.onChange)
      inputProps.onChange({
        target: {
          name: '',
          value: String(inputProps?.value).replace('.', ''),
        },
      } as ChangeEvent<HTMLInputElement>);
  }
  return (
    <Form.Group className={cs('input-field-group', className)} {...groupProps}>
      {label && (
        <Form.Label
          className={cs('input-field-label', labelClassName)}
          {...labelProps}
        >
          {label}
        </Form.Label>
      )}
      <Form.Control
        {...inputProps}
        className={cs('input-field-input', inputClassName)}
        isInvalid={!!error}
        max={max}
        type="number"
        min={min}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default NumberInput;
