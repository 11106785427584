import { matchRoutes, useLocation } from 'react-router-dom';

const routes = [
  { path: '/' },
  { path: '/practice' },
  { path: '/music-library' },
  { path: '/music-library/:id' },
  { path: '/settings' },
];

const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);

  return route.path;
};

export default useCurrentPath;
