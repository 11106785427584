import React, {
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  Dispatch,
} from 'react';
import { AutoCompleteField } from 'components/common';
import { getCategories } from 'services/categories';
import { IoMdClose } from 'react-icons/io';

type CategoryInputProps = {
  userCategories?: number[];
  setUserCategories: Dispatch<SetStateAction<number[]>>;
};

const CategoryInput = (props: CategoryInputProps) => {
  const { userCategories, setUserCategories } = props;
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<
    Array<{ value: number; label: string; customComponent?: ReactElement }>
  >([]);
  const [value, setvalue] = useState<string>('');
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleGetCategories = async () => {
    setIsLoadingCategory(true);
    const { data: categoryData } = await getCategories({ limit: 99 });
    if (categoryData) {
      const categoryOptions = categoryData.results.map((i) => ({
        value: i.id,
        label: i.name,
      }));
      setCategoryOptions(categoryOptions);
    }
    setIsLoadingCategory(false);
  };

  const handleSelect = (value: number) => {
    setUserCategories((prev) => {
      if (prev.includes(value)) {
        return prev;
      } else {
        return [...prev, value];
      }
    });
    setvalue('');
    setIsOpenSelect(false);
  };

  const filteredOptions = useMemo(() => {
    return categoryOptions.filter(
      (i) =>
        (value ? i?.label.toLowerCase().includes(value.toLowerCase()) : true) &&
        !userCategories?.includes(i?.value),
    );
  }, [categoryOptions, value, userCategories]);

  const selectedCategories = useMemo(() => {
    return userCategories.map((i) => ({
      value: i,
      label: categoryOptions.find((j) => j.value === i)?.label || '',
    }));
  }, [categoryOptions, userCategories]);
  return (
    <div className="category-input">
      <AutoCompleteField
        label="Category:"
        inputProps={{
          onFocus: () => setIsOpenSelect(true),
          onBlur: () => {
            setTimeout(() => setIsOpenSelect(false), 100);
          },
          placeholder: 'Search for category...',
        }}
        isClearValueOnSelect={true}
        isOpenSelect={isOpenSelect}
        isLoading={isLoadingCategory}
        options={filteredOptions}
        onChange={setvalue}
        onCallBack={() => {}}
        onSelect={handleSelect}
      />
      <div className="selected-categories">
        {selectedCategories.map((item, index) => (
          <div key={index} className="category-item">
            {item?.label}{' '}
            <IoMdClose
              className="item-icon"
              onClick={() =>
                setUserCategories((prev) => [
                  ...prev.filter((i) => i !== item?.value),
                ])
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryInput;
