import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import { default as cs } from 'classnames';

type BtnProps = ButtonProps & {
  btnClassName?: string;
  type?: string;
  isLoading?: boolean;
};

const Btn = (props: BtnProps) => {
  const { btnClassName, children, variant, isLoading } = props;
  return (
    <Button
      className={cs('button-custom', btnClassName, variant)}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            role="status"
            size="sm"
            className="spinner-btn"
          />{' '}
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};

export default Btn;
