import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getGoals, Goal } from 'services/goals';
import { Circle } from 'rc-progress';
import CreateGoalModal from './CreateGoalModal';

type GoalsProps = {
  goalReload?: number;
};
const Goals = (props: GoalsProps) => {
  const { goalReload } = props;
  const [isLoadingGoal, setIsLoadingGoal] = useState(false);
  const [isShowAddGoalModal, setIsShowAddGoalModal] = useState(false);
  const [goals, setGoals] = useState<Array<Goal>>([]);
  console.log(goalReload);
  useEffect(() => {
    if (goalReload >= 1) {
      handleGetGoals(false);
    } else {
      handleGetGoals();
    }
  }, [goalReload]);

  const handleGetGoals = async (isShowLoading: boolean = true) => {
    if (isShowLoading) setIsLoadingGoal(true);
    const { data, error } = await getGoals({
      limit: 20,
      ordering: '-created',
      embed: 'piece',
    });

    if (data) {
      setGoals(data.results);
    }
    if (error) {
      toast.error('Error getting goals. Please try again later');
    }
    setIsLoadingGoal(false);
  };

  return (
    <>
      <CreateGoalModal
        isShowModal={isShowAddGoalModal}
        handleGetGoals={handleGetGoals}
        handleCloseModal={() => setIsShowAddGoalModal(false)}
      />
      <div className="heading">
        Goals{' '}
        <span
          className="add-goal-text"
          onClick={() => setIsShowAddGoalModal(true)}
        >
          Add goal
        </span>
      </div>
      <div className="goal-wrapper">
        {isLoadingGoal ? (
          <div>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <>
            {goals.length > 0 ? (
              <>
                {goals?.map((item, index) => (
                  <div key={index} className="goal-item home-item">
                    <div className="progress-wrapper">
                      <Circle
                        className="progress-circle"
                        percent={parseInt(item.progress)}
                        strokeWidth={10}
                        trailWidth={10}
                        strokeColor="#2898d1"
                      />
                    </div>
                    <div className="goal-progress">{item.progress}</div>
                    <div className="goal-title">{item.piece?.title}</div>
                  </div>
                ))}
              </>
            ) : (
              <div className="empty-text">There is no goal.</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Goals;
