import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Btn, InputField, InputSelect } from 'components/common';
import { getTimezones, updateUser } from 'services/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CategoryInput from './CategoryInput';
import { User } from 'services/user';
import { setUser } from 'redux/actions';
import { Dispatch } from 'redux';

type EditProfileValues = {
  email: string;
  first_name: string;
  last_name: string;
  timezone: string;
};

const EditProfile = (props: { user?: User; dispatch: Dispatch }) => {
  const { user, dispatch } = props;
  const [timezones, setTimeZones] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userCategories, setUserCategories] = useState<number[]>([]);
  const schema = yup.object().shape({
    email: yup.string().email('Email is invalid').required('Email is required'),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    timezone: yup.string().required('Timezone is required'),
  });

  const formMethods = useForm<EditProfileValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (user) setUserCategories(user?.practice_categories);
  }, [user]);

  useEffect(() => {
    if (user) {
      setValue('email', user?.email);
      setValue('first_name', user?.first_name);
      setValue('last_name', user?.last_name);
      setValue('timezone', user?.timezone);
    }
  }, [user, timezones]);

  useEffect(() => {
    handleGetTimeZones();
  }, []);

  const handleGetTimeZones = async () => {
    const { data, error } = await getTimezones();
    if (data) {
      setTimeZones(data);
    }
  };

  const onSubmit = async (values: EditProfileValues) => {
    setIsLoading(true);
    const payload = {
      email: values?.email,
      first_name: values?.first_name,
      last_name: values?.last_name,
      timezone: values?.timezone,
      practice_categories: userCategories,
      instruments: user?.instruments,
    };
    for (let key in payload) {
      if (payload[key] === '' || payload[key] === undefined) {
        delete payload[key];
      }
    }
    const { data, error } = await updateUser(payload);
    if (data) {
      dispatch(setUser(data?.user));
      toast.success('Update user successfully');
    }
    if (error) {
      toast.error('Error saving profile. Please try again later.');
    }
    setIsLoading(false);
  };

  const timezoneOptions = useMemo(() => {
    return timezones.map((i) => ({ value: i, label: i }));
  }, [timezones]);

  return (
    <div className="edit-profile-tab">
      <FormProvider {...formMethods}>
        <Form className="edit-profile-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={3}>
              <div className="avatar-wrapper">
                <img src={user?.picture} alt="" className="avatar" />
              </div>
            </Col>
            <Col md={9}>
              <Row>
                <Col md={6}>
                  <InputField
                    label="First name:"
                    inputProps={{
                      ...register('first_name'),
                    }}
                  />
                </Col>
                <Col md={6}>
                  <InputField
                    label="Last name:"
                    inputProps={{
                      ...register('last_name'),
                    }}
                  />
                </Col>
                <Col md={6}>
                  <InputField
                    label="Email:"
                    inputProps={{
                      ...register('email'),
                    }}
                  />
                </Col>
                <Col md={6}>
                  <InputSelect
                    label="Timezone"
                    options={timezoneOptions}
                    inputProps={{
                      ...register('timezone'),
                    }}
                  />
                </Col>
                <Col md={6}>
                  <CategoryInput
                    userCategories={userCategories}
                    setUserCategories={setUserCategories}
                  />
                </Col>
              </Row>
              <Row>
                <div>
                  <Btn variant="primary" type="submit" isLoading={isLoading}>
                    Save
                  </Btn>
                </div>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(EditProfile);
