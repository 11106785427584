import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { getPieceById, Piece } from 'services/pieces';
import { toast } from 'react-toastify';
import { getMusicByUrl, Music, Question } from 'services/music';
import { Spinner } from 'react-bootstrap';
import { FiPlay } from 'react-icons/fi';
import YoutubeModal from './YotubeModal';
import moment from 'moment';
import DiscussionModal from './DiscussionModal';
import CreateDiscussionModal from './CreateDiscussionModal';
import { FaRegClock, FaRegChartBar } from 'react-icons/fa';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';
import SetGoalModal from './SetGoalModal';
import { getGoalByUrl, Goal } from 'services/goals';
import { getPracticesStats, getPracticesTimechart } from 'services/practice';
import PdfModal from './PdfModal';
import { formatDuration, generateDateLabels } from 'ultils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);
type PieceObject = Piece & {
  musicObject?: Music;
  goalObject?: Goal;
};

const MusicLibraryItem = (props) => {
  const { user } = props;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [piece, setPiece] = useState<PieceObject | null>(null);
  const [isShowDiscussionModal, setIsShowDiscussionModal] = useState(false);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [isShowCreateDiscussionModal, setIsShowCreateDiscussionModal] =
    useState(false);
  const [isShowSetGoalModal, setIsShowSetGoalModal] = useState(false);
  const [isShowPdfModal, setIsShowPdfModal] = useState(false);
  const [chartData, setChartData] = useState<{
    labels: string[];
    data: number[];
    totalTime: number;
  }>({ labels: [], data: [], totalTime: 0 });
  const [youtubeId, setYoutubeId] = useState('');
  const [question, setQuestion] = useState<Question | null>(null);

  useEffect(() => {
    handleGetPiece();
  }, []);

  const handleGetPiece = async (isSetLoading: boolean = true) => {
    if (isSetLoading) setIsLoading(true);

    const { data, error } = await getPieceById(id);
    if (data) {
      const pieceObject: PieceObject = data;

      if (data.music) {
        const { data: musicData } = await getMusicByUrl(data.music);
        if (musicData) {
          pieceObject.musicObject = musicData;
        }
      }
      if (data.goals.length > 0) {
        const { data: goalData } = await getGoalByUrl(data.goals[0]);
        if (goalData) {
          pieceObject.goalObject = goalData;
        }
      }
      const created_min = moment().subtract(12, 'weeks').toISOString();
      const { data: timeChartData } = await getPracticesTimechart({
        created_min,
        piece: pieceObject.id,
      });
      if (timeChartData) {
        const extractedData = Object.values(timeChartData?.datasets)[0];
        const labels = generateDateLabels(
          extractedData.start_date,
          extractedData.end_date,
        );
        let yearData: number[] = [];
        const monthLabels = generateDateLabels(
          extractedData.start_date,
          extractedData.end_date,
          'year',
        );
        const monthObject = {};
        for (let i = 0; i < labels.length; i++) {
          const currentMonth = labels[i].substring(0, 3);
          if (monthObject[currentMonth]) {
            monthObject[currentMonth] += Number(extractedData.values[i]) || 0;
          } else {
            monthObject[currentMonth] = Number(extractedData.values[i]) || 0;
          }
        }
        yearData = Object.values(monthObject);
        console.log(yearData);
        setChartData({
          labels: monthLabels,
          data: yearData.map((i) => i / 60),
          totalTime: extractedData.total_time,
        });
      }

      setPiece(pieceObject);
    }
    if (error) {
      toast.error('Error getting piece. Please try again later.');
    }
    if (isSetLoading) setIsLoading(false);
  };

  const handleCloseModal = () => {
    setIsShowYoutubeModal(false);
    setIsShowDiscussionModal(false);
    setIsShowCreateDiscussionModal(false);
    setYoutubeId('');
    setQuestion(null);
  };

  const openYoutubeModal = (id: string) => {
    setIsShowYoutubeModal(true);
    setYoutubeId(id);
  };

  const openDicussionModal = (question: Question) => {
    setIsShowDiscussionModal(true);
    setQuestion(question);
  };

  const openCreateDicussionModal = () => {
    setIsShowCreateDiscussionModal(true);
    setQuestion(question);
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      yAxes: {
        display: true,
        beginAtZero: true,
        ticks: { stepSize: 1 },
      },
    },
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        label: 'Minutes practiced',
        borderColor: '#2898d1',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.5,
      },
    ],
  };

  return (
    <div className="music-library-item-container">
      <CreateDiscussionModal
        isShowModal={isShowCreateDiscussionModal}
        music={piece?.musicObject}
        handleCloseModal={handleCloseModal}
        getQuestions={handleGetPiece}
      />
      <DiscussionModal
        isShowModal={isShowDiscussionModal}
        question={question}
        handleCloseModal={handleCloseModal}
      />
      <YoutubeModal
        isShowModal={isShowYoutubeModal}
        id={youtubeId}
        handleCloseModal={handleCloseModal}
      />
      <SetGoalModal
        piece={piece}
        isShowModal={isShowSetGoalModal}
        handleGetPiece={handleGetPiece}
        handleCloseModal={() => setIsShowSetGoalModal(false)}
      />
      <PdfModal
        isShowModal={isShowPdfModal}
        handleCloseModal={() => setIsShowPdfModal(false)}
        music={piece?.musicObject}
      />
      <div className="go-back-button">
        <Link to={'/music-library'}>
          <BsArrowLeft /> Back
        </Link>
      </div>
      {isLoading ? (
        <div>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <div className="item-wrapper">
          <div className="info-section">
            <div className="item-title">{piece?.title}</div>
            <div className="cover-image">
              <img
                src={piece?.musicObject?.sheetmusic_previews?.[0]}
                alt=""
                onClick={() => setIsShowPdfModal(true)}
              />
            </div>
            <div className="hashtag-wrapper">
              {piece?.musicObject?.tags?.map((item, idx) => (
                <div key={idx} className="hashtag-item">
                  {item}
                </div>
              ))}
            </div>
            <div className="video-wrapper">
              {piece?.musicObject?.youtube_video_ids?.map((item, index) => (
                <div
                  className="video-item"
                  key={index}
                  onClick={() => openYoutubeModal(item)}
                >
                  <img
                    src={`https://img.youtube.com/vi/${item}/0.jpg`}
                    alt=""
                  />
                  <FiPlay className="video-icon" />
                </div>
              ))}
            </div>
          </div>
          <div className="main-section">
            <div className="stats-wrapper">
              <div className="section-title">Stats</div>
              <div className="stats-figures">
                <span>
                  <FaRegClock size={16} />{' '}
                  <span className="text">
                    {formatDuration(piece?.practiced_time)}
                  </span>
                </span>{' '}
                &nbsp;|&nbsp;{' '}
                <span>
                  <FaRegChartBar />{' '}
                  <span className="text">{piece?.practices?.length}</span>
                </span>
              </div>
              <div className="progress-wrapper">
                <ProgressBar now={parseInt(piece?.goalObject?.progress)} />
              </div>
              <div className="goal-text-container">
                <div className="goal-text">
                  Goal:{' '}
                  {piece?.goalObject ? (
                    <span onClick={() => ''}>
                      {piece?.goalObject.duration / (60 * 60)} hr
                      {piece?.goalObject.duration / (60 * 60) > 1
                        ? 's'
                        : ''}{' '}
                      per {piece?.goalObject?.interval}
                    </span>
                  ) : (
                    <span onClick={() => setIsShowSetGoalModal(true)}>
                      Set Goal
                    </span>
                  )}
                </div>
                <div>{piece?.goalObject ? piece?.goalObject.progress : ''}</div>
              </div>

              <div className="last-12-weeks-text">Last 12 weeks</div>
              <div className="chart-container">
                <Line options={options} data={data} />
              </div>
            </div>
            <div className="discussion-wrapper">
              <div className="section-title">
                Discussion{' '}
                <span
                  className="start-new-text"
                  onClick={() => openCreateDicussionModal()}
                >
                  Start a dicussion
                </span>
              </div>
              <div className="discussion-list-wrapper">
                {piece?.musicObject?.questions?.length > 0 ? (
                  <>
                    {piece?.musicObject?.questions?.map((item, index) => (
                      <div
                        key={index}
                        className="discustiom-item"
                        onClick={() => openDicussionModal(item)}
                      >
                        <div className="item-title">{item.title}</div>
                        <div className="item-description">
                          {item.description}
                        </div>
                        <div className="item-info">
                          {item.total_answers} answers •{' '}
                          {moment(item.created_date).fromNow()}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="no-dicussion-text">
                    There is no discussion.{' '}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(MusicLibraryItem);
