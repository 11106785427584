import moment from 'moment';

export const secondsToMinutesFormat = (seconds: number) => {
  return `${Math.floor(seconds / 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${(seconds % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

export const formatDuration = (second?: number) => {
  if (second >= 60) {
    const minutes = Math.floor(second / 60);
    const seconds = second - minutes * 60;
    return `${minutes}m${seconds !== 0 ? `${seconds}s` : ''}`;
  } else {
    return `${second}s`;
  }
};

export const generateDateLabels = (
  startDate: string,
  endDate?: string,
  type: string = 'week',
) => {
  if (!endDate) endDate = new Date().toISOString();
  const labels: string[] = [];
  if (type === 'week' || type === 'month') {
    let startDay = moment(startDate).startOf('day');
    labels.push(startDay.format('MMM DD'));
    let endDay = moment(endDate).startOf('day');
    while (startDay < endDay) {
      startDay = startDay.add(1, 'days');
      labels.push(startDay.format('MMM DD'));
    }
  } else if (type === 'year') {
    let startDay = moment(startDate).startOf('month');
    labels.push(startDay.format('MMM'));
    let endDay = moment(endDate).startOf('day');
    while (startDay < endDay) {
      startDay = startDay.add(1, 'months');
      labels.push(startDay.format('MMM'));
    }
  }
  return labels;
};
