import * as core from './core';
import { Piece } from './pieces';

export type Practice = {
  id?: string;
  duration?: number;
  notes?: string;
  created?: string;
  practice_category?: number | string;
  practice_category_text?: string;
  piece?: Piece;
};

export type GetPractices = {
  count: number;
  next: number | null;
  previous: number | null;
  results: Array<Practice>;
};

export const getPractices = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetPractices;
  error?: string;
}> => {
  return core.get('/practices/', filter) as Promise<{
    data: GetPractices;
    error?: string;
  }>;
};

export const savePracticeSession = async (
  payload: Omit<Practice, 'piece'> & { piece: string },
): Promise<{
  data: Practice;
  error?: string;
}> => {
  return core.post('/practices/', payload) as Promise<{
    data: Practice;
    error?: string;
  }>;
};

type GetPracticesTimeChart = {
  datasets: {
    [key: string]: {
      start_date: string;
      end_date: string;
      total_time: number;
      values: number[];
    };
  };
};

export const updatePracticeSession = async (
  id: string | number,
  payload: Omit<Practice, 'piece'> & { piece: string },
): Promise<{
  data: Practice;
  error?: string;
}> => {
  return core.patch(`/practices/${id}/`, payload) as Promise<{
    data: Practice;
    error?: string;
  }>;
};

export const getPracticesTimechart = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetPracticesTimeChart;
  error?: string;
}> => {
  return core.get('/practices/timechart/', filter) as Promise<{
    data: GetPracticesTimeChart;
    error?: string;
  }>;
};

type GetPracticesStat = {
  [key: string]: {
    duration: number;
    sessions: number;
  };
};

export const getPracticesStats = async (filter?: {
  [key: string]: any;
}): Promise<{
  data: GetPracticesStat;
  error?: string;
}> => {
  return core.get('/practices/stats/', filter) as Promise<{
    data: GetPracticesStat;
    error?: string;
  }>;
};
