import React, { useEffect, forwardRef, HTMLProps } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { default as cs } from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { setUser } from 'redux/actions';
import useCurrentPath from 'hooks/userCurrentPath';
import {
  AiOutlineHome,
  AiOutlinePlayCircle,
  AiOutlineSetting,
} from 'react-icons/ai';
import { FiMusic } from 'react-icons/fi';

type CustomToggleProps = HTMLProps<HTMLDivElement> & {
  avatar: string;
};

const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  ({ onClick, avatar }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="avatar"
      style={{ backgroundImage: `url(${avatar})` }}
    ></div>
  ),
);

const MainLayout = (props) => {
  const { children, user, dispatch } = props;
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {};
  }, []);

  const items = [
    {
      title: 'Home',
      route: '/',
      matchingRoutes: ['/'],
      icon: <AiOutlineHome className="item-icon" />,
    },
    {
      title: 'Practice',
      route: '/practice',
      matchingRoutes: ['/practice'],
      icon: <AiOutlinePlayCircle className="item-icon" />,
    },
    {
      title: 'Music Library',
      route: '/music-library',
      matchingRoutes: ['/music-library', '/music-library/:id'],
      icon: <FiMusic className="item-icon" />,
    },
    {
      title: 'Settings',
      route: '/settings',
      matchingRoutes: ['/settings'],
      icon: <AiOutlineSetting className="item-icon" />,
    },
  ];

  const handleLogout = () => {
    dispatch(setUser(null));
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  return (
    <div className="main-layout">
      <div className="side-bar">
        <div className="side-bar-heading">Musopen Practice</div>
        <div className="side-bar-items">
          {items.map((item) => (
            <Link
              to={item.route}
              className={cs(
                'item-link-wrapper',
                item.matchingRoutes.includes(currentPath) ? 'active' : '',
              )}
            >
              <div className="icon-wrapper">{item.icon}</div>
              <div
                className={cs(
                  'item',
                  item.matchingRoutes.includes(currentPath) ? 'active' : '',
                )}
              >
                {item.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="main-area">
        <div className="top-bar">
          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-avatar"
              avatar={user?.picture}
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" onClick={() => handleLogout()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.connectionReducer.user,
});

export default connect(mapStateToProps)(MainLayout);
